import styled from "styled-components";
import QuestionComponent from "../../components/QuestionComponent";
import {useEffect} from "react";


const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 25px;
`

const TitleText = styled.p`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  
  margin-top: 60px;
  margin-bottom: 30px;
`




const FaqList = [
    {
        question: "Доставка и оплата",
        answer: [
            "Мы доставляем заказы по России. Для разных регионов мы предлагаем индивидуальные условия доставки и оплаты.",
            "Стандартная доставка в пунтк выдачи и  доставка курьером через компанию «СДЭК»",
            "Доставка Почтой России.",
            "Бесплатная доставка при заказе на сумму от 10000 рублей.",
            "Способы оплаты: Visa, Mastercard, Мир, Система быстрых платежей",
        ],
    },
    {
        question: "Возврат",
        answer: [
            "Возврат заказа осуществляется частично и полностью при наличии брака,. Возврат товаров осуществляется в течение 3-х дней с момента покупки(получения заказа) при наличии на полного комплекта маркировки на изделии. Изделие должно быть не ношенным совсем.\n" +
            "При наличии брака мы компенсируем стоимость доставки, а также стоимость пересылки бракованного изделия обратно. Если бракованное изделие было частью заказа, стоимость доставки не компенсируется. Стоимость пересылки бракованного изделия компенсируется в любом случае."
        ],
    },
    {
        question: "Что делать, если заказ был оформлен на сайте, а мне никто не перезвонил и не подтвердил доставку?",
        answer: [
            "Мы стараемся оперативно связываться с нашими клиентами и подтверждать информацию по заказу. Обычно это занимает не более 24 часов.Бывает так, что на сайте проходят технические работы, и информация о заказе может поступить с небольшой задержкой. Подобные ситуации случаются редко. Но если так случилось с Вашим заказом, мы приносим свои извинения и просим Вас связаться с нами по контактам, указанным на сайте."
        ],
    },
    {
        question: "Я хочу сделать возврат. Когда мне вернут деньги?",
        answer: [
            "Возврат заказа осуществляется частично и полностью при наличии брака,. Возврат товаров осуществляется в течение 3-х дней с момента покупки(получения заказа) при наличии на полного комплекта маркировки на изделии. Изделие должно быть не ношенным совсем.\n" +
            "При наличии брака мы компенсируем стоимость доставки, а также стоимость пересылки бракованного изделия обратно. Если бракованное изделие было частью заказа, стоимость доставки не компенсируется. Стоимость пересылки бракованного изделия компенсируется в любом случае."
        ],
    },
];



const DostavkaPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <GlobalWrapper>

            <TitleText> Условия доставки и возврата товара</TitleText>
            {FaqList.map((item) => (
                <QuestionComponent question={item.question} answer={item.answer}/>
            ))}
        </GlobalWrapper>
    )
}


export default DostavkaPage