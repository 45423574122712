import styled from 'styled-components';
import {useState} from "react";
import {CATEGORY_LIST} from '../utils/mock_data'
import downImg from "../assets/images/downarrow2.png"
import AddItemInCartComp from "./AddItemInCartComp";


const MainWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  user-select: none;

  p {
    margin-bottom: 0;
    margin-top: 4px;
    color: black;
  }
  
  @media(max-width: 768px) {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    max-width: calc(100% - 20px);
  }
`

const ItemImage = styled.img`
  width: 150px;
  height: 188px;
  object-fit: cover;
  margin-bottom: 12px;
  
  @media(max-width: 768px) {
    width: 150px;
    height: 188px;
  }
`

const Text = styled.a`
  height: 32px;
  max-width: 260px;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  color: #252525;
  transition: ease 0.15s;
  &:hover {
    color: #cc2c1e;
  }
  @media(max-width: 768px) {
    width: 150px;
  }
`
const MoneyText = styled.p`
`

const FlexColumn = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-left: 16px;
`

const BuyButton = styled.a`
  background: #cc2c1e;
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 12px 16px;
  font-size: 14px;
  
  p {
    color: #fff;
  }

  &:hover {
    background: #da291c;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
    max-width: calc(100% - 30px);
  }
`;

const DropdownComponent = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-top: 22px; 
`;
const DropDownMainWrapper = styled.div`
  white-space: nowrap;
  border: 1px solid #d2d2d2;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    margin-left: 8px;
    
    @media(max-width: 768px) {
      margin-left: 4px;
    }
  }
`

const ColorItem = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  margin-right: 10px;
`

const DropdownSpacer = styled.div`
  height: 20px;
  width: 20px; 
  margin-left: 10px;
`
const DropdownContent = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;  
  position: absolute;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #333;
  transform: translateY(calc(50% + 20px));
  width: 248px;
  padding: 15px 0;
  font-size: 14px;
  p {
    width: 100%;
    text-align: end;
  }
  @media(max-width: 768px) {
    width: 194px;
  }
`
const DropdownButton = styled.div`
  color: black;
  width: 200px;
  height: 36px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0);
  background: ${props => props.isActive ? "#f5f5f5" : "#fff"};
  
  @media(max-width: 768px) {
    width: 140px;
    height: 42px;
  }

  &:hover {
    border: 1px solid #000;
  }
`
const DropdownText = styled.p`
  padding: 0;
  margin: 0;
  width: 170px;
  
  @media(max-width: 768px) {
    width: 120px;
  }
`

const RecItemComponent = (props) => {

    const itemId = props.id
    const itemsList = props.itemsList
    const item = itemsList.find(x => x.id === itemId)
    const categoryId = item.categoryId;
    const cartItems = props.cartItems
    const setCartItems = props.setCartItems

    const [activeColor, setActiveColor] = useState(0);
    const [activeSize, setActiveSize] = useState(0);
    const [addItemBlock, setAddItemBlock] = useState(false);
    const [lastBuyedItem, setLastBuyedItem] = useState({
        name: item.name,
        image: `${item.img[0]}`,
        color: item.colors[activeColor].name,
        size: CATEGORY_LIST[categoryId].sizes[activeSize],
        price: item.price
    })

    const addToCart = () => {
        const isExist = cartItems.length > 0;
        const index = isExist && cartItems.findIndex(x => x.id === itemId
            && x.color === item.colors[activeColor]
            && x.size === CATEGORY_LIST[categoryId].sizes[activeSize]);
        if (!isExist || index < 0) {
            const content = {
                name: itemsList[itemId].name,
                id: itemId,
                color: item.colors[activeColor],
                size: CATEGORY_LIST[categoryId].sizes[activeSize],
                amount: 1
            }
            setCartItems([...cartItems, content]);
            localStorage.setItem("cartList", JSON.stringify([...cartItems, content]));
        } else {
            const arr = [...cartItems];
            const amnt = arr[index].amount;
            arr[index] = {
                name: itemsList[itemId].name,
                id: itemId,
                color: item.colors[activeColor],
                size: CATEGORY_LIST[categoryId].sizes[activeSize],
                amount: amnt + 1,
            };
            setCartItems(arr);
            localStorage.setItem("cartList", JSON.stringify(arr));
        }
        setLastBuyedItem({
            name: item.name,
            image: `${item.img[0]}`,
            color: item.colors[activeColor].name,
            size: CATEGORY_LIST[categoryId].sizes[activeSize],
            price: item.price
        })
        setAddItemBlock(true)
        setTimeout(() => setAddItemBlock(false),5000)
    };

    function stopTimerAndCloseWindow() {
        setAddItemBlock(false)
        const highestId = window.setTimeout(() => {
            for (let i = highestId; i >= 0; i--) {
                window.clearInterval(i);
            }
        }, 0);
    }

    const [dropOpen, setDropOpen] = useState(false)
    const [sizeDropOpen, setSizeDropOpen] = useState(false)
    const selectColor = (id) => {
        setActiveColor(id)
        setDropOpen(false)
    }
    const selectSize = (id) => {
        setActiveSize(id)
        setSizeDropOpen(false)
    }

    return (
       <>
           {addItemBlock && <AddItemInCartComp
               name={lastBuyedItem.name}
               addItemBlock={addItemBlock}
               closeWindow={stopTimerAndCloseWindow}
               image={lastBuyedItem.image}
               color={lastBuyedItem.color}
               size={lastBuyedItem.size}
               price={lastBuyedItem.price}/>
           }
           <MainWrapper>
               <a href={`/item/?item=${itemId}`}>
                   <ItemImage src={`${item.img[0]}`} alt="" />
               </a>
               <FlexColumn>
                   <Text href={`/item/?item=${itemId}`}> {item.name} </Text>
                   <MoneyText> {item.price} ₽</MoneyText>
                   <DropdownComponent>
                       <DropDownMainWrapper onClick={() => setDropOpen(!dropOpen)}>
                           <ColorItem style={{background: item.colors[activeColor].color}}/>
                           <DropdownText> {item.colors[activeColor].name} </DropdownText>
                           <img src={downImg} alt="" />
                       </DropDownMainWrapper>
                       <DropdownContent style={{display: dropOpen ? "flex" : "none"}}>
                           {item.colors.map((x, id) => (
                               <DropdownButton isActive={activeColor === id} onClick={() => selectColor(id)}>
                                   <ColorItem style={{background: x.color, marginLeft: 10}}/>
                                   <p> {x.name} </p>
                               </DropdownButton>
                           ))}
                       </DropdownContent>
                   </DropdownComponent>
                   <DropdownComponent>
                       <DropDownMainWrapper onClick={() => setSizeDropOpen(!sizeDropOpen)}>
                       <DropdownText> {CATEGORY_LIST[categoryId].sizes[activeSize]} </DropdownText>
                       <DropdownSpacer />
                       <img src={downImg} alt="" />
                   </DropDownMainWrapper>
                       <DropdownContent style={{display: sizeDropOpen ? "flex" : "none"}}>
                           {CATEGORY_LIST[categoryId].sizes.map((x, id) => (
                               <DropdownButton isActive={activeSize === id} onClick={() => selectSize(id)}>
                                   <p> {x} </p>
                               </DropdownButton>
                           ))}
                       </DropdownContent>
                   </DropdownComponent>

                   <BuyButton onClick={addToCart}>
                       <p>ДОБАВИТЬ В КОРЗИНУ</p>
                   </BuyButton>
               </FlexColumn>
           </MainWrapper>
       </>
    )
}

export default RecItemComponent;
