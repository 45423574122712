import MarketPage from "../MarketPage";


const AccessoriesPage = (props) => {
    return (
        <MarketPage itemsList={props.itemsList} categoryId={6} />
    )
}


export default AccessoriesPage