import styled from "styled-components";
import {useMemo} from "react";
import {CATEGORY_LIST} from "../../utils/mock_data";
import SellItemsComponent from "../../components/SellItemsComponent";

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px;
  @media (max-width: 78px) {
    max-width: 100%;
  }
`;

const BorderLine = styled.div`
  width: 100%;
  height: 0.3px;
  background: #d2d2d2;
  margin-bottom: 22px;

  @media (max-width: 768px) {
    max-width: calc(100% - 20px);
  }
`;

const SellItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    max-width: calc(100% - 20px);
  }
`;

const TitleText = styled.p`
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: left;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 26px;
    margin-left: 30px;
    width: calc(100% - 30px);
  }
`;


const MarketPage = (props) => {

    const sortId = props.categoryId;
    const itemsList = props.itemsList;
    const sortedItemsByCategory = useMemo(() => itemsList.filter(sortedItem => sortedItem && sortedItem.categoryId === sortId), [itemsList]);
    const categoryList = CATEGORY_LIST[sortId].name;
    console.log(itemsList)
    console.log(CATEGORY_LIST)

    return (
        <MainWrapper>
            <TitleText>{categoryList}</TitleText>
            <BorderLine/>
            <SellItemsWrapper>
                {sortedItemsByCategory && sortedItemsByCategory.map((item, id) => (
                    <SellItemsComponent item={item} id={id} itemsList={props.itemsList}/>
                ))}
            </SellItemsWrapper>
        </MainWrapper>
    );
};


export default MarketPage;
