import styled from 'styled-components';
import {useState} from "react";
import ModalInput from "./ModalInput";
import CardImg from "../assets/images/card.png";
import {Link} from "react-router-dom";

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: calc(100% - 20px);
  }
`;


const ContinueButton = styled.a`
  background: #000000;
  font-size: 16px;
  color: #fff;
  transition: 0.2s;
  text-transform: uppercase;
  cursor: pointer;
  width: 270px;
  text-align: center;
  padding: 20px 0;
  height: 24px;
  margin: 10px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    width: 27px;
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    margin: 0;
    margin-left: 10px;
    margin-bottom: 14px;
  }
`;

const StyledLink = styled(ContinueButton).attrs({
    as: Link
})`
  text-decoration: none;
`;

const Text = styled.p`
  font-size: 17px;
  text-transform: uppercase;
  width: 1000px;
  margin-left: 20px;
  margin-top: 45px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const MiniText = styled.p`
  font-size: 15px;
  width: 1000px;
  margin-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ModalsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
`;
const RowToColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    margin-right: 20px;
    margin-left: 20px;
  }
`;


const PayWrapper = styled(RowToColumn)`
  justify-content: start;
  margin-top: 24px;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 24px;
    justify-content: start;
    width: 100%;
  }
`;

const CheckoutComponent = (props) => {

    // данные для оформления заказа
    const [country, setCountry] = useState("Россия");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    // мессенджеры
    const [tg, setTg] = useState("");
    const [whatsApp, setWhatsApp] = useState("");


    function validateEmail(input) {
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (input.match(validRegex)) {
            return true;
        }
        return false;
    }

    const isEmailValid = validateEmail(email);
    const emailError = email.length > 0 && !isEmailValid;
    const nameAndCityError = name.length <= 1 || city.length <= 1;
    const [showWrongEmail, setShowWrongEmail] = useState(false);

    const finishCheckout = (orderType) => {
        if (!emailError && email.length > 4 && !nameAndCityError) {
            props.checkoutAction(getUserData(), orderType);
        } else setShowWrongEmail(true);
    };

    const getUserData = () => {
        const userData = {
            country: country,
            city: city,
            address: address,
            customer: {
                name: name,
                surname: surname,
                phone: phone,
                tg: tg,
                whatsApp: whatsApp,
                email: email
            }
        };
        return userData;
    };

    return (
        <MainWrapper>
            <Text> Страна </Text>
            <ModalsWrapper>
                <ModalInput placeholder="Страна" value={country} setValue={setCountry}/>
            </ModalsWrapper>

            <Text> АДРЕС ПОЛУЧЕНИЯ</Text>
            <ModalsWrapper>
                <ModalInput placeholder="Город" value={city} setValue={setCity}/>
                <ModalInput placeholder="Адрес доставки" value={address} setValue={setAddress}/>
            </ModalsWrapper>

            <Text> Ваши данные</Text>
            <ModalsWrapper>
                <ModalInput placeholder="Имя" value={name} setValue={setName}/>
                <ModalInput placeholder="Фамилия" value={surname} setValue={setSurname}/>
                <ModalInput placeholder="Телефон" value={phone} setValue={setPhone}/>
                <ModalInput placeholder="Электронная почта" value={email} setValue={setEmail} emailError={emailError}/>
            </ModalsWrapper>

            <Text> Мессенджеры</Text>
            <MiniText>При необходимости укажите мессенджер для связи</MiniText>
            <ModalsWrapper>
                <ModalInput placeholder="Telegram" value={tg} setValue={setTg}/>
                <ModalInput placeholder="WhatsApp" value={whatsApp} setValue={setWhatsApp}/>
            </ModalsWrapper>

            <ModalsWrapper
                style={{flexDirection: "column", alignItems: "start", justifyContent: "center", marginBottom: 100}}>
                {showWrongEmail &&
                    <p style={{textAlign: "left", width: "calc(100% - 40px)", marginTop: 10, marginLeft: 10}}>Для
                        завершения
                        заказа укажите существующий Email, Город и Имя</p>}
                <PayWrapper>
                    <ContinueButton onClick={() => finishCheckout(2)}>
                        <img src={CardImg} alt=""/>
                        <p> По карте</p>
                    </ContinueButton>
                    <ContinueButton onClick={() => finishCheckout(1)}>
                        Сертификат
                    </ContinueButton>

                </PayWrapper>
            </ModalsWrapper>
        </MainWrapper>
    );
};

export default CheckoutComponent;
