import styled from 'styled-components';
import {useCallback} from "react";

const StyledInput = styled.input`
  padding: 12px 16px; 
  background: white;
  transition: 0.2s;
  margin: 10px;
  font-size: 17px;
  width: 300px;
  
  
  &:hover {
    border: 1px solid #000;
  }
  
  @media(max-width: 768px) {
    width: 100%;
  }
`


const ModalInput = (props) => {

    const placeholder = props.placeholder
    const value = props.value
    const setValue = props.setValue
    const emailError = props.emailError

    const handleChange = useCallback((e) => {
        setValue(e.currentTarget.value)
    }, [setValue])


    return (
        <StyledInput placeholder={placeholder}
                     value={value}
                     onChange={handleChange}
                     style={{border: `1px solid ${emailError ? "#ef0808" : "#999"}`}}/>
    )
}

export default ModalInput;