// BACKEND CONTENT
export const colors = [
    {
        name: "черный", // 0
        color: "#000000",
    },
    {
        name: "белый", // 1
        color: "#ffffff",
    },
    {
        name: "красный", // 2
        color: "#ff0000",
    },
    {
        name: "серебристый пион", // 3
        color: "#EFDDC5",
    },
    {
        name: "фуксия", // 4
        color: "#f754e1",
    },
    {
        name: "пыльная Роза", // 5
        color: "#B5727F",
    },
    {
        name: "синий", // 6
        color: "#0000ff",
    },
    {
        name: "желтый неон", // 7
        color: "#faed27",
    },
    {
        name: "розовый неон", // 8
        color: "#fc0fc0",
    },
    {
        name: "экрю", // 9
        color: "#eeceac",
    },
    {
        name: "лавандовый", // 10
        color: "#e6e6fa",
    },
    {
        name: "золотой", // 11
        color: "#ffd700",
    },
    {
        name: "серебряный", // 12
        color: "#c0c0c0",
    },
    {
        name: "голубой", // 13
        color: "#42aaff",
    },
    {
        name: "серый", // 14
        color: "#808080",
    },
];
export const materials = [
    "вышивка", //0
    "кружево", // 1
    "сетка", // 2
    "хлопок", //3
    "атлас", //4
    "бархат", //5
    "искусственный шелк", //6
    "перья", //7
    "блестящий материал", //8
    "вискоза", //9
    "микрофибра" // 10
];
export const itemsList = [
    {
        "img": [
            "/images/domcostum3-1.jpg",
            "/images/domcostum3-2.jpg",
            "/images/domcostum3-3.jpg"
        ],
        "name": "Домашний костюм с брюками",
        "price": "5500",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 106
    },
    {
        "img": [
            "/images/bust4-1.jpg",
            "/images/bust4-2.jpg",
            "/images/bust4-3.jpg"
        ],
        "name": "Бюстье из атласа с кружевом",
        "price": "4500",
        "categoryId": 2,
        "materials": [
            "атлас",
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 18
    },
    {
        "img": [
            "/images/gartery-na-nozhki-1.jpg",
            "/images/gartery-na-nozhki-2.jpg",
            "/images/gartery-na-nozhki-3.jpg",
            "/images/gartery-na-nozhki-4.jpg",
            "/images/gartery-na-nozhki-5.jpg"
        ],
        "name": "Гартеры на ножки",
        "price": "899",
        "categoryId": 5,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "синий",
                "color": "#0000ff"
            }
        ],
        "id": 1
    },
    {
        "img": [
            "/images/bust-with-butterfly-1.jpg",
            "/images/bust-with-butterfly-2.jpg",
            "/images/bust-with-butterfly-3.jpg",
            "/images/bust-with-butterfly-4.jpg"
        ],
        "name": "Бюстгальтер с бабочками",
        "price": "2999",
        "categoryId": 4,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 2
    },
    {
        "img": [
            "/images/str-na-zav-1.jpg",
            "/images/str-na-zav-2.jpg",
            "/images/str-na-zav-3.jpg"
        ],
        "name": "Стринги на завязках",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "вышивка"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 3
    },
    {
        "img": [
            "/images/trusi-slipy-1.jpg",
            "/images/trusi-slipy-2.jpg"
        ],
        "name": "Трусы слипы",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "вышивка"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 4
    },
    {
        "img": [
            "/images/trusi-stringi-1.jpg",
            "/images/trusi-stringi-2.jpg"
        ],
        "name": "Трусы стринги",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "вышивка"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 5
    },
    {
        "img": [
            "/images/bust-1.jpg",
            "/images/bust-2.jpg",
            "/images/bust-3.jpg",
            "/images/bust-4.jpg"
        ],
        "name": "Бюстгальтер DALLIANCE",
        "price": "3000",
        "categoryId": 4,
        "materials": [
            "вышивка"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 6
    },
    {
        "img": [
            "/images/poyas-dlya-zulok-1.jpg",
            "/images/poyas-dlya-zulok-2.jpg",
            "/images/poyas-dlya-zulok-3.jpg"
        ],
        "name": "Пояс для чулок BLACK SWAN 2",
        "price": "2300",
        "categoryId": 5,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 9
    },
    {
        "img": [
            "/images/trusi-stringi3-1.jpg",
            "/images/trusi-stringi3-2.jpg",
            "/images/trusi-stringi3-3.jpg",
            "/images/trusi-stringi3-4.jpg"
        ],
        "name": "Трусы стринги",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 12
    },
    {
        "img": [
            "/images/holterbra-1.jpg",
            "/images/holterbra-2.jpg",
            "/images/holterbra-3.jpg"
        ],
        "name": "Холтер-бра PASSION",
        "price": "3399",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 13
    },
    {
        "img": [
            "/images/trusi-slipy1-1.jpg",
            "/images/trusi-slipy1-2.jpg",
            "/images/trusi-slipy1-3.jpg",
            "/images/trusi-slipy1-4.jpg"
        ],
        "name": "Трусы слипы",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "атлас",
            "кружево"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 17
    },
    {
        "img": [
            "/images/poyas-dlya-zulok2-1.jpg",
            "/images/poyas-dlya-zulok2-2.jpg"
        ],
        "name": "Пояс для чулок DEMURE",
        "price": "2500",
        "categoryId": 5,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 19
    },
    {
        "img": [
            "/images/trusi-stringi4-1.jpg",
            "/images/trusi-stringi4-2.jpg"
        ],
        "name": "Трусы стринги",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 20
    },
    {
        "img": [
            "/images/bust5-1.jpg",
            "/images/bust5-2.jpg"
        ],
        "name": "Бюстгальтер DEMURE",
        "price": "3000",
        "categoryId": 4,
        "materials": [
            "сетка",
            "бархат"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 21
    },
    {
        "img": [
            "/images/trusi-stringi4-2.jpg",
            "/images/trusi-stringi4-1.jpg"
        ],
        "name": "Бюстгальтер Push-up BLACK SWAN",
        "price": "3700",
        "categoryId": 4,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 23
    },
    {
        "img": [
            "/images/trusi-slipy2-1.jpg",
            "/images/trusi-slipy2-2.jpg",
            "/images/trusi-slipy2-3.jpg"
        ],
        "name": "Трусы Слипы RED",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 26
    },
    {
        "img": [
            "/images/poyas-dlya-zulok3-1.jpg",
            "/images/poyas-dlya-zulok3-2.jpg"
        ],
        "name": "Пояс для чулок DEMURE RED",
        "price": "2500",
        "categoryId": 5,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 28
    },
    {
        "img": [
            "/images/trusi-stringi5-1.jpg",
            "/images/trusi-stringi5-2.jpg",
            "/images/trusi-stringi5-3.jpg"
        ],
        "name": "Трусы стринги",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 29
    },
    {
        "img": [
            "/images/bust-otk-1.jpg",
            "/images/bust-otk-2.jpg",
            "/images/bust-otk-3.jpg"
        ],
        "name": "Открытый бюстгальтер DEMURE",
        "price": "2100",
        "categoryId": 4,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 33
    },
    {
        "img": [
            "/images/trusi-stringi-zav-1.jpg",
            "/images/trusi-stringi-zav-2.jpg",
            "/images/trusi-stringi-zav-3.jpg"
        ],
        "name": "Трусы стринги с завязками",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "сетка"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 34
    },
    {
        "img": [
            "/images/poyas-dlya-zulok4-1.jpg",
            "/images/poyas-dlya-zulok4-2.jpg"
        ],
        "name": "Пояс для чулок RED",
        "price": "2700",
        "categoryId": 5,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 36
    },
    {
        "img": [
            "/images/bust9-0.jpg",
            "/images/bust9-1.jpg",
            "/images/bust9-2.jpg",
            "/images/bust9-3.jpg",
            "/images/bust9-4.jpg"
        ],
        "name": "Бюстгальтер ABUNDANCE",
        "price": "3500",
        "categoryId": 4,
        "materials": [
            "сетка",
            "кружево"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 40
    },
    {
        "img": [
            "/images/bust10-1.jpg",
            "/images/bust10-2.jpg"
        ],
        "name": "Бюстгальтер RED 1",
        "price": "2700",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 41
    },
    {
        "img": [
            "/images/poyas-dlya-zulok5-1.jpg",
            "/images/poyas-dlya-zulok5-2.jpg",
            "/images/poyas-dlya-zulok5-3.jpg"
        ],
        "name": "Пояс для чулок",
        "price": "3000",
        "categoryId": 5,
        "materials": [
            "перья"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 44
    },
    {
        "img": [
            "/images/poyas-dlya-zulok6-1.jpg",
            "/images/poyas-dlya-zulok6-2.jpg"
        ],
        "name": "Пояс для чулок SWAN",
        "price": "2700",
        "categoryId": 5,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 45
    },
    {
        "img": [
            "/images/trusi-slipy4-1.jpg",
            "/images/trusi-slipy4-2.jpg",
            "/images/trusi-slipy4-3.jpg"
        ],
        "name": "Трусы Слипы SWAN",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "сетка",
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 46
    },
    {
        "img": [
            "/images/trusi-slipy5-1.jpg",
            "/images/trusi-slipy5-2.jpg",
            "/images/trusi-slipy5-3.jpg"
        ],
        "name": "Трусы слипы",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 47
    },
    {
        "img": [
            "/images/trusi-1.jpg",
            "/images/trusi-2.jpg",
            "/images/trusi-3.jpg"
        ],
        "name": "Трусы",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "атлас",
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 49
    },
    {
        "img": [
            "/images/bust13-0.jpg",
            "/images/bust13-1.jpg",
            "/images/bust13-2.jpg",
            "/images/bust13-3.jpg"
        ],
        "name": "Бюстгальтер SWAN",
        "price": "3500",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 50
    },
    {
        "img": [
            "/images/bust14-1.jpg",
            "/images/bust14-2.jpg",
            "/images/bust14-3.jpg"
        ],
        "name": "Бюстгальтер SWAN 1",
        "price": "2700",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 51
    },
    {
        "img": [
            "/images/bust16-1.jpg",
            "/images/bust16-2.jpg",
            "/images/bust16-3.jpg"
        ],
        "name": "Бюстье с поролоновой чашкой SWAN",
        "price": "5000",
        "categoryId": 2,
        "materials": [
            "атлас",
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 53
    },
    {
        "img": [
            "/images/poyas-dlya-zulok8-1.jpg",
            "/images/poyas-dlya-zulok8-2.jpg",
            "/images/poyas-dlya-zulok8-3.jpg"
        ],
        "name": "Пояс для чулок с перьями",
        "price": "3000",
        "categoryId": 5,
        "materials": [
            "перья"
        ],
        "colors": [
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            }
        ],
        "id": 66
    },
    {
        "img": [
            "/images/trusi11-1.jpg",
            "/images/trusi11-2.jpg"
        ],
        "name": "Стринги из бархата",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "бархат",
            "сетка"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 81
    },
    {
        "img": [
            "/images/poyas-dlya-zulok10-1.jpg",
            "/images/poyas-dlya-zulok10-2.jpg",
            "/images/poyas-dlya-zulok10-3.jpg"
        ],
        "name": "Пояс для чулок DEMURE BLOSSOM",
        "price": "2500",
        "categoryId": 5,
        "materials": [
            "бархат",
            "сетка"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 82
    },
    {
        "img": [
            "/images/bust32-1.jpg",
            "/images/bust32-2.jpg",
            "/images/bust32-3.jpg"
        ],
        "name": "Бюстгальтер с открытой чашкой DEMURE",
        "price": "2100",
        "categoryId": 4,
        "materials": [
            "бархат"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 86
    },
    {
        "img": [
            "/images/poyas-dlya-zulok11-1.jpg",
            "/images/poyas-dlya-zulok11-2.jpg",
            "/images/poyas-dlya-zulok11-3.jpg"
        ],
        "name": "Пояс для чулок с перьями",
        "price": "3000",
        "categoryId": 5,
        "materials": [
            "перья"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 87
    },
    {
        "img": [
            "/images/poyas-dlya-zulok12-1.jpg",
            "/images/poyas-dlya-zulok12-2.jpg",
            "/images/poyas-dlya-zulok12-3.jpg"
        ],
        "name": "Пояс для чулок BLOSSOM",
        "price": "2700",
        "categoryId": 5,
        "materials": [
            "сетка",
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 88
    },
    {
        "img": [
            "/images/trusi12-1.jpg",
            "/images/trusi12-2.jpg"
        ],
        "name": "Трусы Бразильяны BLOSSOM",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "сетка",
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 89
    },
    {
        "img": [
            "/images/bust34-1.jpg",
            "/images/bust34-2.jpg",
            "/images/bust34-3.jpg"
        ],
        "name": "Бюстгальтер BLOSSOM",
        "price": "3500",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 92
    },
    {
        "img": [
            "/images/bust35-1.jpg",
            "/images/bust35-2.jpg",
            "/images/bust35-3.jpg"
        ],
        "name": "Бюстгальтер Push-up BLOSSOM",
        "price": "3700",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 93
    },
    {
        "img": [
            "/images/bust36-1.jpg",
            "/images/bust36-2.jpg"
        ],
        "name": "Бюстгальтер ease",
        "price": "1800",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 94
    },
    {
        "img": [
            "/images/bust37-1.jpg",
            "/images/bust37-2.jpg"
        ],
        "name": "Бюстгальтер BLOSSOM 3",
        "price": "2700",
        "categoryId": 4,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 95
    },
    {
        "img": [
            "/images/bust39-1.jpg",
            "/images/bust39-2.jpg",
            "/images/bust39-3.jpg"
        ],
        "name": "Бюстье с поролоновой чашкой BLOSSOM",
        "price": "5000",
        "categoryId": 2,
        "materials": [
            "кружево"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 97
    },
    {
        "img": [
            "/images/halat2-1.jpg",
            "/images/halat2-2.jpg",
            "/images/halat2-3.jpg"
        ],
        "name": "Халат домашний",
        "price": "4000",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "фуксия",
                "color": "#f754e1"
            }
        ],
        "id": 101
    },
    {
        "img": [
            "/images/halat3-1.jpg",
            "/images/halat3-2.jpg",
            "/images/halat3-3.jpg"
        ],
        "name": "Халат домашний",
        "price": "4000",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            }
        ],
        "id": 102
    },
    {
        "img": [
            "/images/halat4-1.jpg",
            "/images/halat4-2.jpg"
        ],
        "name": "Халат домашний",
        "price": "4000",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            }
        ],
        "id": 103
    },
    {
        "img": [
            "/images/domcostum2-1.jpg",
            "/images/domcostum2-2.jpg",
            "/images/domcostum2-3.jpg"
        ],
        "name": "Домашний костюм с кантом",
        "price": "6000",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            }
        ],
        "id": 105
    },
    {
        "img": [
            "/images/domcostum6-1.jpg",
            "/images/domcostum6-2.jpg",
            "/images/domcostum6-3.jpg"
        ],
        "name": "Домашний костюм с кантом",
        "price": "6000",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "id": 109
    },
    {
        "img": [
            "/images/bust42-1.jpg",
            "/images/bust42-2.jpg",
            "/images/bust42-3.jpg"
        ],
        "name": "Топ SEXY BASE",
        "price": "2500",
        "categoryId": 4,
        "materials": [
            "искусственный шелк"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "id": 113
    },
    {
        "img": [
            "/images/bodi3-1.jpg",
            "/images/bodi3-2.jpg",
            "/images/bodi3-3.jpg",
            "/images/bodi3-4.jpg",
            "/images/bodi3-5.jpg"
        ],
        "name": "Боди TORY",
        "price": "3500",
        "categoryId": 0,
        "materials": [
            "сетка"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "розовый неон",
                "color": "#fc0fc0"
            },
            {
                "name": "желтый неон",
                "color": "#faed27"
            }
        ],
        "id": 122
    },
    {
        "img": [
            "/images/Transparent3-1.jpg",
            "/images/Transparent3-2.jpg",
            "/images/Transparent3-3.jpg"
        ],
        "name": "Топ Transparent",
        "price": "1500",
        "categoryId": 4,
        "materials": [
            "сетка"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 123
    },
    {
        "img": [
            "/images/Transparent3-0.jpg",
            "/images/Transparent3-1.jpg",
            "/images/Transparent3-2.jpg",
            "/images/Transparent3-3.jpg",
            "/images/Transparent3-4.jpg"
        ],
        "name": "Трусы Transparent",
        "price": "1000",
        "categoryId": 3,
        "materials": [
            "сетка"
        ],
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "id": 124
    },
    {
        "img": [
            "/images/Transparent2-0.jpg",
            "/images/Transparent2-1.jpg",
            "/images/Transparent2-2.jpg",
            "/images/Transparent2-3.jpg",
            "/images/Transparent2-4.jpg",
            "/images/Transparent2-5.jpg",
            "/images/Transparent2-6.jpg"
        ],
        "name": "Топ transparent",
        "price": "1500",
        "categoryId": 4,
        "materials": [
            "микрофибра"
        ],
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "желтый неон",
                "color": "#faed27"
            },
            {
                "name": "розовый неон",
                "color": "#fc0fc0"
            }
        ],
        "id": 125
    },
    {
        "name": "Купальник Sensation",
        "price": "1500",
        "categoryId": 7,
        "materials": [
            "микрофибра"
        ],
        "id": 223,
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            },
            {
                "name": "серый",
                "color": "#808080"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/23-1.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/23-2.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/23-3.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/23-4.jpg"
        ]
    },
    {
        "name": "Купальник Orchid",
        "price": "3500",
        "categoryId": 7,
        "materials": [
            "блестящий материал"
        ],
        "id": 224,
        "colors": [
            {
                "name": "золотой",
                "color": "#ffd700"
            },
            {
                "name": "лавандовый",
                "color": "#e6e6fa"
            },
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/24-1.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/24-2.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/24-3.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/24-4.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/24-5.jpg"
        ]
    },
    {
        "name": "Комбинация ",
        "price": "4500",
        "categoryId": 1,
        "materials": [
            "искусственный шелк"
        ],
        "id": 226,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/26-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/26-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/26-3.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/26-4.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/26-5.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/26-6.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/26-7.jpg"
        ]
    },
    {
        "name": "Бюстгальтер Angel  ",
        "price": "3000",
        "categoryId": 4,
        "materials": [
            "сетка",
            "кружево",
            "искусственный шелк"
        ],
        "id": 227,
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/27-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/27-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/27-3.JPG"
        ]
    },
    {
        "name": "Пояс для чулок ANGEL",
        "price": "2700",
        "categoryId": 5,
        "materials": [
            "искусственный шелк",
            "сетка",
            "кружево"
        ],
        "id": 230,
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/30-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/30-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/30-3.JPG"
        ]
    },
    {
        "name": "Трусы Стринги Angel",
        "price": "1600",
        "categoryId": 3,
        "materials": [
            "кружево",
            "сетка",
            "искусственный шелк",
            "хлопок"
        ],
        "id": 231,
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/31-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/31-2.JPG"
        ]
    },
    {
        "name": "Слипы Angel  ",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "кружево",
            "искусственный шелк",
            "хлопок"
        ],
        "id": 232,
        "colors": [
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/32-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/32-2.JPG"
        ]
    },
    {
        "name": "Боди Pose ",
        "price": "3500",
        "categoryId": 0,
        "materials": [
            "хлопок",
            "сетка",
            "перья"
        ],
        "id": 236,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/36-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/36-2.JPG"
        ]
    },
    {
        "name": "Боди Pose ",
        "price": "3500",
        "categoryId": 0,
        "materials": [
            "сетка",
            "хлопок",
            "перья"
        ],
        "id": 237,
        "colors": [
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/37-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/37-2.JPG"
        ]
    },
    {
        "name": "Бюстгальтер SEXY BASE",
        "price": "4000₽",
        "categoryId": 4,
        "materials": [
            "искусственный шелк"
        ],
        "id": 239,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/39-3.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/39-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/39-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/39-4.jpg"
        ]
    },
    {
        "name": "Стринги SEXY BASE",
        "price": "1800",
        "categoryId": 3,
        "materials": [
            "хлопок",
            "искусственный шелк"
        ],
        "id": 240,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/40-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/40-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/40-3.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/40-4.jpg"
        ]
    },
    {
        "name": "Пояс для чулок SEXY BASE",
        "price": "2500",
        "categoryId": 5,
        "materials": [
            "искусственный шелк"
        ],
        "id": 242,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "фуксия",
                "color": "#f754e1"
            },
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/42-3.jpg",
            "https://storage.yandexcloud.net/clothing-store-products/42-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/42-2.JPG",
        ]
    },
    {
        "name": "Резинка Silk",
        "price": "500",
        "categoryId": 6,
        "materials": [
            "атлас"
        ],
        "id": 243,
        "colors": [
            {
                "name": "черный",
                "color": "#000000"
            },
            {
                "name": "красный",
                "color": "#ff0000"
            },
            {
                "name": "серебристый пион",
                "color": "#EFDDC5"
            },
            {
                "name": "пыльная Роза",
                "color": "#B5727F"
            },
            {
                "name": "голубой",
                "color": "#42aaff"
            },
            {
                "name": "белый",
                "color": "#ffffff"
            }
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/43-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/43-2.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/43-3.JPG"
        ]
    },
    {
        "name": "Пояс для чулок LOVE",
        "price": "2500",
        "categoryId": 5,
        "materials": [
            "кружево"
        ],
        "id": 250,
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            },
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/50-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/50-2.JPG",
        ]
    },
    {
        "name": "Стринги LOVE 2",
        "price": "1500",
        "categoryId": 3,
        "materials": [
            "хлопок",
            "бархат"
        ],
        "id": 249,
        "colors": [
            {
                "name": "красный",
                "color": "#ff0000"
            },
        ],
        "img": [
            "https://storage.yandexcloud.net/clothing-store-products/49-1.JPG",
            "https://storage.yandexcloud.net/clothing-store-products/49-2.JPG",
        ]
    },
];
export const mainBannerItems = [
    40, 41, 51, 50
];
export const topSellerItems = [
    6, 92, 13, 94
];

export const CATEGORY_LIST = [
    {
        name: "Боди",
        sizes: ["xs", "s", "m", "l"],
        link: "/body"
    },
    {
        name: "Одежда для дома",
        sizes: ["s", "m", "l"],
        link: "/home"
    },
    {
        name: "Бюстье",
        sizes: ["70A", "70B", "70C", "70D",
            "75A", "75B", "75C", "75D",
            "80A", "80B", "80C",
            "85A", "85B"
        ],
        link: "/bustie"
    },
    {
        name: "Трусики",
        sizes: ["xxs", "xs", "s", "m", "l", "xl"],
        link: "/trusiki"

    },
    {
        name: "Бюстгальтеры",
        sizes: ["70A", "70B", "70C", "70D",
            "75A", "75B", "75C", "75D",
            "80A", "80B", "80C",
            "85A", "85B"
        ],
        link: "/byustgaltery"
    },
    {
        name: "Пояса для чулок",
        sizes: ["xs", "s", "m", "l"],
        link: "/poyasa"
    },
    {
        name: "Аксессуары",
        sizes: ["one size"],
        link: "/accessories"
    },
    {
        name: "Купальники",
        sizes: ["one size"],
        link: "/kupalniki"
    },
    {
        name: "Сертификат",
        sizes: ["1000"],
        link: "/certificat"
    },
];
export const RECCOMENDATIONS_LIST = [
    [3, 20, 19, 1],
    [6, 4, 5],
    [9, 1, 17],
    [9, 1, 17],
    [9, 1, 17],
    [9, 1, 17],
    [13, 12, 1],
    [12, 1],
    [21, 19, 20, 1],
    [22, 19, 20, 1, 17],
    [23, 19, 20, 1, 17],
    [29, 28, 1],
    [29, 28, 1],
    [33, 29, 28, 1],
    [29, 28, 1],
    [26, 34, 1, 36],
    [26, 34, 1, 36],
    [40, 26, 34, 1, 36],
    [41, 26, 34, 1, 36],
    [26, 34, 1, 36],
    [26, 34, 1, 36],
    [26, 34, 1, 36],
    [50, 45, 44, 1, 46, 47, 49],
    [53, 45, 44, 1, 46, 47, 49],
    [51, 45, 44, 1, 46, 47, 49],
    [45, 44, 1, 46, 47, 49],
    [1, 66],
    [1, 66],
    [99, 1, 66],
    [81, 82, 1],
    [85, 81, 82, 1],
    [86, 81, 82, 1],
    [92, 89, 1, 88, 87],
    [93, 89, 1, 88, 87],
    [94, 89, 1, 88, 87],
    [95, 89, 1, 88, 87],
    [89, 1, 88, 87],
    [123, 124],
    [111, 113],
    [101, 102, 103, 105, 107, 109],
];
