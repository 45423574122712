import styled from 'styled-components'
import DownArrow from "../assets/images/downarrow.png"
import {useState} from "react";

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid #a7b6bd;
  width: calc(100% - 200px);
  max-width: 1000px;
  text-align: left;
  
  h1{
    font-size: 16px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 700;
    @media(max-width: 768px) {
      font-size: 11px;
    }
  }
  p {
    font-weight: 400;
    @media(max-width: 768px) {
      font-size: 11px;
    }
  }
  @media(max-width: 768px) {
    width: calc(100% - 40px);
  }
`


const TouchRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  h1 {
    width: calc(100% - 100px);
  }
  p {
    width: calc(100% - 100px);
  }
`
const ArrowImage = styled.img`
  width: 24px;
  cursor: pointer;
  filter: invert(10%);
  transition: .15s ease-in-out;
  border-radius: 100%;
  padding: 6px;
`

const ArrowImageRotate = styled(ArrowImage)`
  transform: rotate(180deg);
`

const QuestionComponent = ({question, answer}) => {

    const [isClicked, setIsClicked] = useState(false)

  return (
      <QuestionWrapper>

          <TouchRowWrapper>
              <h1>{question}</h1>
              <ArrowImage src={DownArrow}
                          alt=""
                          onClick={() => setIsClicked(true)}
                          style={{display: !isClicked ? "block" : "none"}}/>
          </TouchRowWrapper>

          <TouchRowWrapper style={{display: isClicked ? "flex" : "none"}}>
                 <div>
                     {answer.map((item) => (
                         <p>{item}</p>
                     ))}
                 </div>
              <ArrowImageRotate src={DownArrow}
                          alt=""
                          onClick={() => setIsClicked(false)}
                              />
          </TouchRowWrapper>
      </QuestionWrapper>
  )
}

export default QuestionComponent
