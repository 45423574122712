import MarketPage from "../MarketPage";


const HomeMarketPage = (props) => {
    return (
        <MarketPage itemsList={props.itemsList} categoryId={1} />
    )
}


export default HomeMarketPage