import styled from 'styled-components';
import Logo from "../assets/logos/black_pilinia.png";
import Instagram from "../assets/logos/instagram.svg";
import Vk from "../assets/logos/vk.png";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import x from "../assets/images/x.png";


const CartBlock = styled.div`
  position: fixed;
  bottom: 52px;
  left: 52px;
  background: white;
  width: 100%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  padding-bottom: 20px;
  padding-top: 20px;
  
  img {
    width: 65px;
    height: 83px;
    padding: 0 20px;
    object-fit: scale-down;
  }
  
  @media(max-width: 768px) {
    max-width: 100%;
    bottom: 0;
    left: 0;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  width: calc(100% - 18px);
  margin-top: 20px;
`
const GoCartButton = styled(Link)`
  background: #cc2c1e;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
  width: calc(100% - 60px);
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-decoration: none;
  margin-bottom: 10px;
  
  p {
    font-size: 14px;
  }

  &:hover {
    background: #da291c;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  p {
    margin-top: 0;
  }
`

const MainText = styled.p`
  letter-spacing: 2.4px;
  text-transform: uppercase;
  font-size: 15px;
`
const CloseImage = styled.img`
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  transition: 0.2s;
  position: absolute;
  top: 24px;
  right: 6px;

  &:hover {
    opacity: 0.5;
  }
`;



const AddItemInCartComp = (props) => {

    return (
        <CartBlock style={{display: props.addItemBlock ? "flex" : "none"}}>
            <CloseImage src={x} alt="" onClick={props.closeWindow}/>
            <MainText>Товар добавлен в корзину</MainText>
            <FlexRow>
                <img src={props.image} alt="" />
                <TextWrapper>
                    <p> {props.name}</p>
                    <p style={{color: "grey"}}>Цвет: {props.color}</p>
                    <p style={{color: "grey"}}>Размер: {props.size}</p>
                    <p> {props.price} ₽</p>
                </TextWrapper>
            </FlexRow>
            <GoCartButton to="/cart">
                <p>ПЕРЕЙТИ В КОРЗИНУ</p>
            </GoCartButton>
        </CartBlock>

    );
};

export default AddItemInCartComp;