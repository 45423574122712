import styled from "styled-components";
import SellItemsComponent from "../../components/SellItemsComponent";
import {useEffect} from "react";


const FavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
  min-height: calc(100vh - 400px);
  width: 100%;
  margin-bottom: 50px;
`;

const ContinueButton = styled.a`
  background: #000;
  padding: 10px 20px;
  font-size: 15px;
  color: #fff;
  transition: 0.2s;
  text-decoration: none;
  //border-radius: 5px;
  &:hover {
    opacity: 0.75;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }
`;

const FavPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const wishList = props.wishList;
    const noFavItems = wishList.length < 1;
    const itemList = props.itemList;
    const findItemInListByIndex = (id) => itemList.find(searchItem => searchItem.id.toString() === id)

    return (
        <FavWrapper>

            <p>{noFavItems && "Нет товаров в списке желаемого"}</p>
            {noFavItems && <ContinueButton href="/">НАЧАТЬ ПОКУПКИ</ContinueButton>}

            {!noFavItems && <h1 style={{fontWeight: 300, fontSize: 24}}>Избранное</h1>}
            {!noFavItems &&
            <ItemsWrapper>
                {wishList && wishList.map((wishItem, id) => (
                    <SellItemsComponent item={findItemInListByIndex(wishItem.id)} id={id} itemsList={itemList}/>
                ))}
            </ItemsWrapper>
            }
        </FavWrapper>
    );
};


export default FavPage;