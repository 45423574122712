import styled from 'styled-components';
import Logo from "../assets/logos/black_pilinia.png"
import Shop from "../assets/images/shop.png"
import FavImage from "../assets/images/favorite.png"
import {useState} from "react";
import {CATEGORY_LIST} from '../utils/mock_data';
import {Link} from "react-router-dom";
import MobileMenu from  "../assets/images/menu.png"
import x from "../assets/images/x.png";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px; 
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`

const CategoryWrapper = styled(MainWrapper)`
  height: 52px;
  @media(max-width: 768px) {
    display: none;
  }
`

const DisplayLimitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  @media(max-width: 768px){
    max-width: 100%;
  }
`
const CartLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  transition: 0.2s;
  text-decoration: none;
  width: 55px;
  user-select: none;
  margin-right: 10px;
  &:hover{
    opacity: 0.5;
  } 
  
  @media(max-width: 768px) {
    width: 45px;
  }
`


const ItemLink = styled(Link)`
  margin: 24px;
  transition: 0.2s;
  opacity: ${props => props.isHovered ? 1 : 0.5};
  cursor: pointer;
  text-decoration: none;
  color: #000;
`
const RightLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

const MadeInText = styled.p`
  font-size: 12px;
  @media(max-width: 768px) {
    display: none;
  }
`

const MobileMenuImg = styled.img`
  width: 30px;
  margin-left: 15px;
 
`
const MobileMenuButton = styled.a`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
  @media(max-width: 768px) {
    display: flex;
  }
`
const MobileMenuWrapper = styled.div`
  display: none;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  @media(max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const CloseImage = styled.img`
  width: 20px;
  cursor: pointer;
  transition: 0.2s;
  position: absolute;
  top: 50px;
  right: 50px;

  &:hover {
    opacity: 0.5;
  }
`;

const LogoPilinia = styled.img`
  height: 35px;
  @media(max-width: 768px){
    height: 30px;
  }
`

const Header = (props) => {

    const [isHovered, setIsHovered] = useState(null);
    const [openMobile, setOpenMobile] = useState(false)
    const cartLength = props.cartItems.length;

    return (
        <HeaderWrapper>
        <MainWrapper>
            <DisplayLimitWrapper>
                <MadeInText>Российское производство</MadeInText>
                <MobileMenuButton onClick={() => setOpenMobile(true)}>
                    <MobileMenuImg src={MobileMenu} alt="" />
                </MobileMenuButton>
                <Link to="/">
                    <LogoPilinia src={Logo} alt="" />
                </Link>
                <RightLinksWrapper>
                    <CartLink to="/wishlist">
                        <img src={FavImage} alt="" height={20} />
                        <p style={{marginLeft: 5, fontSize: 14}}> {props.favAmount > 0 && props.favAmount}</p>
                    </CartLink>
                    <CartLink to="/cart" >
                        <img src={Shop} alt="" height={28} style={{paddingRight: 5}}/>
                        <p  style={{marginLeft: 5, fontSize: 14}}>{cartLength > 0 && cartLength}</p>
                    </CartLink>
                </RightLinksWrapper>
            </DisplayLimitWrapper>
        </MainWrapper>

            <CategoryWrapper>
                {CATEGORY_LIST.map((item, id) =>  (
                    <ItemLink
                        to={item.link}
                        isHovered={isHovered === id || !isHovered}
                        onMouseEnter={() => setIsHovered(id)}
                        onMouseLeave={() => setIsHovered(null)}>{item.name}</ItemLink>
                ))}
            </CategoryWrapper>
            {openMobile && <MobileMenuWrapper>
                <CloseImage src={x} alt="" onClick={() => setOpenMobile(false)}/>
                {CATEGORY_LIST.map((item, id) =>  (
                    <ItemLink
                        to={item.link}
                        isHovered={isHovered === id || !isHovered}
                        onClick={() => setOpenMobile(false)}
                        onMouseEnter={() => setIsHovered(id)}
                        onMouseLeave={() => setIsHovered(null)}>{item.name}</ItemLink>
                ))}
            </MobileMenuWrapper>}
        </HeaderWrapper>

    )
}

export default Header;
