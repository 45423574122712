import styled from "styled-components";
import {useCallback, useState} from "react";
import {CATEGORY_LIST} from "../../utils/mock_data";
import {Link} from "react-router-dom";

const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BuyButton = styled.a`
  background: #000000;
  font-size: 16px;
  color: #fff;
  transition: 0.2s;
  text-transform: uppercase;
  cursor: pointer;
  width: 340px;
  text-align: center;
  padding: 12px 0;
  height: 24px;
  margin: 10px;
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    width: 27px;
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    margin: 0;
    margin-bottom: 14px;
  }
`;

const InfoText = styled.p`
  font-size: 15px;
  text-align: left;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;
const Bg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 370px;
  background-image: url("/images/bg/bg-sert-5.jpeg");
  background-position: center;
  background-size: cover;
  flex-direction: column;
  color: #ffffff;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    height: 260px;
  }

  h1 {
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 0;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
  }
`;


const StyledInput = styled.input`
  padding: 12px 16px;
  background: white;
  transition: 0.2s;
  margin: 10px;
  font-size: 17px;
  width: 300px;
  border: 1px solid #ddd;
  outline: none;


  &:hover {
    border: 1px solid #000;
  }

  @media (max-width: 768px) {
    width: calc(100% - 34px);
  }
`;

const StyledLink = styled(BuyButton).attrs({
    as: Link
})`
  text-decoration: none;
`;

const CertificatesPage = ({setCertificates, certificates, setCartItems, cartItems}) => {

    const [certPrice, setCertPrice] = useState("");
    const handleChangePrice = useCallback((e) => {
        setCertPrice(e.currentTarget.value);
    }, [setCertPrice]);

    const isCartValueGood = parseInt(certPrice) >= 1000;
    const addToCart = () => {
        if (isCartValueGood) {

            const certs = localStorage.getItem('certificates') ? JSON.parse(localStorage.getItem('certificates')) : [];
            console.log('LAST: ', certs);

            const certId = certs.at(-1)?.id ?? 0;

            const content = {
                name: "Сертификат",
                id: Number(certId) - 1,
                color: "NONE",
                size: "NONE",
                amount: 1,
            };
            if (certs?.find(it => it.price === certPrice)) {
                setCartItems([...cartItems, certs.filter(it => it.price === certPrice)[0]]);
                localStorage.setItem("cartList", JSON.stringify([...cartItems, certs.filter(it => it.price === certPrice)[0]]));
            } else {
                setCartItems([...cartItems, content]);
                localStorage.setItem("cartList", JSON.stringify([...cartItems, content]));

                setCertificates([...certificates, {
                    ...content, price: certPrice
                }]);
                localStorage.setItem("certificates", JSON.stringify([...certificates, {
                    ...content, price: certPrice
                }]));
            }
            setCertPrice('');
        }
    };

    return (
        <GlobalWrapper>
            <MainWrapper>
                {/*<TitleText>Сертификат</TitleText>*/}
                {/*<BorderLine/>*/}
                <Bg>
                    <h1>Подарочный сертификат</h1>
                    <p>Сертификат универсальный подарок на каждый случай!</p>
                </Bg>
                <p>Введите сумму для покупки сертификата</p>
                <ModalWrapper>
                    <StyledInput placeholder="Любая сумма от 1000 руб"
                                 value={certPrice}
                                 onChange={handleChangePrice}/>

                </ModalWrapper>
                <StyledLink to={isCartValueGood ? '/cart' : '/certificat'} onClick={addToCart} style={{background: certPrice.length > 0 && !isCartValueGood && "#7a7979"}}>
                    ДОБАВИТЬ В КОРЗИНУ
                </StyledLink>

                <InfoText style={{marginTop: 120}}>
                    Срок действия сертификата - 1 год. Сертификат действует на всю продукцию магазина.
                </InfoText>
                <InfoText>Сертификат является именным и приобретается в наших розничных магазинах;
                    после покупки сертификата, его можно сразу использовать для оплаты товаров в нашем магазине по
                    адресу г. Новосибирск, Советская 8 или онлайн.
                </InfoText>
            </MainWrapper>
        </GlobalWrapper>
    );
};


export default CertificatesPage;
