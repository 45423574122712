import styled from "styled-components";
import SellItemsComponent from "../../components/SellItemsComponent";
import {useEffect} from "react";
import Logo from "../../assets/logos/black_pilinia.png";

const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

const LogoPilinia = styled.img`
  height: 35px;
  @media(max-width: 768px){
    height: 30px;
  }
`


const PreloadPage = () => {

    return (
        <GlobalWrapper>
            <LogoPilinia src={Logo} alt="" />
            <p>Сайт на обновлении.</p>
        </GlobalWrapper>
    )
}


export default PreloadPage