import styled from "styled-components";
import SellItemsComponent from "../../components/SellItemsComponent";
import {useEffect} from "react";

const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

const BannerAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  height: 400px;
  margin-top: 100px;
  user-select: none;
  padding-bottom: 30px;
  
  @media(max-width: 768px) {
    height: 300px;
    margin-top: 50px;
    flex-direction: column;
  }
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    @media (max-width: 768px) {
      width: calc(100% - 20px);
      height: 500px;
    }
  }
`
const AboutMiddleDisplay = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  width: 100%;
  background: #000;
  background-image: url("/images/bg/bg-landing.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  //filter: grayscale(100%);
  color: #ffffff;

  h1 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0;
    @media(max-width: 768px){
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
    @media(max-width: 768px){
      font-size: 14px;
    }
  }
`

const SellItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`


const IndexPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const itemList = props.itemList
    const findItemInListByIndex = (id) => itemList.find(searchItem => searchItem.id === id)

    return (
        <GlobalWrapper>
            <MainWrapper style={{marginTop: 32, maxWidth: 1200}}>
                <p style={{fontSize: 24, fontWeight: 300, letterSpacing: 5}}>НОВИНКИ</p>
                <SellItemsWrapper>
                    {props.mainBannerItems.map((item, id) => (
                        <SellItemsComponent item={findItemInListByIndex(item)} id={id} itemsList={props.itemsList}/>
                    ))}
                </SellItemsWrapper>
            </MainWrapper>

            <MainWrapper style={{marginTop: 32, maxWidth: 1200}}>
                <p style={{fontSize: 24, fontWeight: 300, letterSpacing: 5}}>ХИТЫ ПРОДАЖ</p>
                <SellItemsWrapper>
                    {props.topSellerItems.map((item, id) => (
                        <SellItemsComponent item={findItemInListByIndex(item)} id={id} itemsList={props.itemsList}/>
                    ))}
                </SellItemsWrapper>
            </MainWrapper>

            <BannerAbout>
                <AboutMiddleDisplay>
                    <h1> Дизайнер Лоскутова Полина</h1>
                    <p> Вы можете найти нас по адресу Новосибирск, Советская 8 или заказать онлайн</p>
                </AboutMiddleDisplay>
            </BannerAbout>

        </GlobalWrapper>
    )
}


export default IndexPage