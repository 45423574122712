import styled from 'styled-components';
import RecItemComponent from "./RecItemComponent";
import {RECCOMENDATIONS_LIST} from '../utils/mock_data';
import {useEffect, useState} from "react";


const AdditionalText = styled.p`
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 5px;
  text-align: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    max-width: calc(100% - 20px);
    flex-direction: column;
  }
`;

const RecommendationsComponent = (props) => {

    const item = props.item;
    const itemId = item.id;
    const itemList = props.itemList;
    const cartItems = props.cartItems
    const setCartItems = props.setCartItems

    const [reccomendItem, setRccomendItem] = useState([])
    useEffect(() => {
        const list = RECCOMENDATIONS_LIST.find(x => x.includes(itemId));
        const n = 2
        const newList = list && list.filter(x => {return x !== itemId})
        const randomItems = newList && newList.sort(() => .5 - Math.random()).slice(0, n);
        setRccomendItem(randomItems);
    }, [itemId])

    return (
        <>
            {reccomendItem &&
            <>
                <AdditionalText> ДОПОЛНИТЬ ОБРАЗ</AdditionalText>
                <FlexRow>
                    {reccomendItem && reccomendItem.map((x, id) => x && <RecItemComponent
                        id={x}
                        setCartItems={setCartItems}
                        itemsList={itemList}
                        cartItems={cartItems}/>)}
                </FlexRow>
            </>
            }
        </>

    );
};

export default RecommendationsComponent;
