import styled from "styled-components";
import {useEffect, useMemo, useState} from "react";
import x from "../../assets/images/x.png";
import CheckoutComponent from "../../components/CheckoutComponent";
import axios from "axios";
import logoMini from "../../assets/images/pilinia_mini.png"
import {useLocation, useNavigate} from "react-router";


const CartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  flex-direction: column;
  min-height: calc(100vh - 400px);
  width: 100%;
  margin-bottom: 50px;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 22px;

  @media (max-width: 768px) {
    margin-right: 0;
    p {
      width: 56px;
      padding-right: 12px;
      padding-left: 12px;
    }
  }
`;

const ContinueButton = styled.a`
  background: #000000;
  font-size: 15px;
  color: #fff;
  transition: 0.2s;
  text-decoration: none;
  cursor: pointer;
  width: 282px;
  text-align: center;
  padding: 16px 0;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

const MainCartWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 200px);
  height: 100%;
  margin-top: 22px;

  @media (max-width: 768px) {
    width: calc(100% - 22px);
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  min-width: 240px;
  width: 100%;

  @media (max-width: 768px) {
    width: calc(100% - 22px);
  }
`;

const ItogoWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 250px;
  margin-bottom: 20px;
  background: rgb(247, 247, 247);
  padding: 8px 16px;
  @media (max-width: 768px) {
    width: calc(100% - 70px);
  }
`;

const CardItemImg = styled.img`
  width: 110px;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 600px;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  @media (max-width: 768px) {
    width: 100%;
    max-width: calc(100% - 10px);
    align-items: start;
  }
`;

const RoundButton = styled.button`
  border: 1px solid #f2f2f2;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  color: #000;
  background: none;
  cursor: pointer;
  transition: 0.2s;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #cc2c1e;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;
const Spacer = styled.div`
  margin: 50px;
`;
const CloseImage = styled.img`
  width: 14px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

const TextWrapper = styled.div`
  margin: 0;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    p {
      font-size: 12px;
    }
  }
`;

const RowToColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const UpperInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
`

const HelperText = styled.p`
  color: gray;
  margin-top: 0;
  font-size: 12px;
`

const AnimLoader = styled.div`
  padding: 10px;
  border: 0.5px solid #000000;
  border-radius: 100%;

  img {
    width: 36px;
    animation: spin infinite linear 1.2s;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const CartPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cartItems = props.cartItems;
    const certificatesItems = props.certificates;
    const noCartItems = cartItems.length < 1;
    const itemList = props.itemList;
    const findItemInListByIndex = (id) => itemList.find(searchItem => searchItem.id.toString() === id)

    const [cartStatus, setCartStatus] = useState(1);
    const price = useMemo(() =>
            cartItems.reduce((sum, item) => {
                return sum + parseFloat(item.id >= 0 ? findItemInListByIndex(item.id).price : certificatesItems.filter(it => it.id === item.id)[0].price) * item.amount;
            }, 0),
        [itemList, cartItems]
    );
    console.log(cartItems)

    const maxCartAmount = 10;
    const minCartAmount = 1;

    const addAmount = (index) => {
        const arr = [...cartItems];
        const amnt = arr[index].amount;
        const itemId = arr[index].id;
        const color = arr[index].color;
        const size = arr[index].size;
        const name = arr[index].name;

        arr[index] = {
            name: name,
            id: itemId,
            color: color,
            size: size,
            amount: amnt >= maxCartAmount ? amnt : amnt + 1,
        };
        props.setCartItems(arr);
        localStorage.setItem("cartList", JSON.stringify(arr));
    };
    const minusAmount = (index) => {
        const arr = [...cartItems];
        const name = arr[index].name;
        const amnt = arr[index].amount;
        const itemId = arr[index].id;
        const color = arr[index].color;
        const size = arr[index].size;


        arr[index] = {
            name: name,
            id: itemId,
            color: color,
            size: size,
            amount: amnt === minCartAmount ? amnt : amnt - 1,
        };

        props.setCartItems(arr);
        localStorage.setItem("cartList", JSON.stringify(arr));
    };

    const removeFromCart = (index) => {
        const arr = [...cartItems];

        const newCert = [...certificatesItems.filter((it) => it.id !== arr[index].id)];

        arr.splice(index, 1);
        props.setCartItems(arr);
        localStorage.setItem("cartList", JSON.stringify(arr));

        props.setCertificates(newCert);
        localStorage.setItem("certificates", JSON.stringify(newCert));
    };

    const checkoutAction = (id) => {
        setCartStatus(id);
    };

    const getCartItems = () => {
        const newArr = cartItems.slice();
        cartItems.map((x, id) => {
             if (x.color.name) {
                newArr[id].color = x.color.name
            }
        })
        return newArr
    }

    const getCartCheckoutItems = () => {
        const newArr = cartItems.slice();
        cartItems.map((x, id) => {
            if (x.name === "Сертификат") {
                newArr[id].amount = certificatesItems.filter(it => it.id === x.id)[0].price
            }
            if (x.color.name) {
                newArr[id].color = x.color.name
            }
        })
        return newArr
    }

    const navigate = useNavigate();
    const checkoutEnd = (userData, orderType) => {
        console.log(orderType)
        if (orderType === 1) {
            setCartStatus(4);
        } else setCartStatus(3)
        const orderInfo = {
            ...userData,
            order: {
                // id: 222,
                PaymentType: orderType,
                products: getCartCheckoutItems(),
                totalPrice: price,
            },
        };
        axios.post('https://functions.yandexcloud.net/d4e46fc3dakqel52apun', orderInfo, {
            headers: {"Access-Control-Allow-Origin": "*"}
        })
            .then(function (response) {
                console.log(response);
                if (orderType === 2) {
                    navigate(`/order/${response.data.redirect.substring(29)}`)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        const arr = [];
        props.setCartItems(arr);
        localStorage.setItem("cartList", JSON.stringify(arr));

    };

    return (
        <CartWrapper>
            <p>{noCartItems && cartStatus === 1 && "Нет товаров в корзине"}</p>
            {noCartItems && cartStatus === 1 && <ContinueButton href="/">НАЧАТЬ ПОКУПКИ</ContinueButton>}
            {!noCartItems &&
                <p style={{fontSize: 24, fontWeight: 300, letterSpacing: 5}}>
                    {cartStatus === 1 && "КОРЗИНА" || cartStatus === 2 && "ОФОРМЛЕНИЕ ЗАКАЗА"}
                </p>}
            {cartStatus === 2 && <CheckoutComponent checkoutAction={checkoutEnd}/>}
            {cartStatus === 3 &&
                <InfoWrapper>
                    <AnimLoader>
                        <img src={logoMini} alt=""/>
                    </AnimLoader>
                    <p>Загрузка...</p>
                </InfoWrapper>}
            {cartStatus === 4 && <><p>Спасибо за заказ! С вами свяжется наш специалист.</p></>}

            {!noCartItems && cartStatus === 1 &&
                <MainCartWrapper>
                    <InfoWrapper>
                        {cartItems.map((item, index) =>
                            <ItemWrapper>
                                <CardItemImg
                                    src={item.id >= 0 ? `${findItemInListByIndex(item.id).img[0]}` : 'images/cert/cert-1.jpeg'}
                                    alt=""/>
                                <RowToColumn>
                                    <TextWrapper>
                                        <p> {item.id >= 0 ? findItemInListByIndex(item.id).name : item.name}</p>
                                        {item.id >= 0 && <p> Размер: {item.size}</p>}
                                        {item.id >= 0 && <p> Цвет: {item.color.name}</p>}
                                    </TextWrapper>

                                    <FlexRow>
                                        <RoundButton onClick={() => minusAmount(index)}>
                                            <p>−</p>
                                        </RoundButton>
                                        <p style={{width: 46}}>{item.amount} шт.</p>
                                        <RoundButton onClick={() => addAmount(index)}>
                                            <p>+</p>
                                        </RoundButton>
                                    </FlexRow>
                                    <div>
                                        <p>{item.id >= 0 ? findItemInListByIndex(item.id).price : Number(certificatesItems.filter(it => it.id === item.id)[0].price)} ₽</p>
                                    </div>
                                </RowToColumn>
                                <CloseImage src={x} alt="" onClick={() => removeFromCart(index)}/>
                            </ItemWrapper>
                        )}
                    </InfoWrapper>

                    <Spacer/>

                    <InfoWrapper>
                        <ItogoWrapper>
                            <UpperInfoWrapper>
                                <p> Итого</p>
                                <p> {Number(price.toFixed(0))} ₽</p>
                            </UpperInfoWrapper>
                        </ItogoWrapper>
                        <ContinueButton onClick={() => checkoutAction(2)}>
                            Оформление заказа
                        </ContinueButton>
                    </InfoWrapper>
                </MainCartWrapper>
            }
        </CartWrapper>
    );
};


export default CartPage;
