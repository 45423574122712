import styled from 'styled-components';
import {Link} from "react-router-dom";
import {useEffect} from "react";


const MainWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  p {
    margin-bottom: 0;
    margin-top: 4px;
    color: black;
  }

`
const ItemImage = styled.img`
  width: 270px;
  object-fit: cover;
  height: 350px;
  margin-bottom: 12px;
  user-select: none;
  pointer-events: none;
  @media(max-width: 768px) {
    width: 160px;
    height: 250px;
  }
`

const Text = styled.p`
  height: 70px;
  max-width: 260px;
  text-align: center;
  @media(max-width: 768px) {
    width: 160px;
    max-width: 160px;
  }
`
const MoneyText = styled.p`
  height: 25px;
`

const SellItemsComponent = (props) => {

    return (
        <MainWrapper to={`/item/?item=${props.item.id}`}>
            <ItemImage src={`${props.item.img[0]}`} alt="" />
            <Text> {props.item.name} </Text>
            <MoneyText> {props.item.price} ₽</MoneyText>
        </MainWrapper>

    )
}

export default SellItemsComponent;