import MarketPage from "../MarketPage";


const BustgalteryPage = (props) => {
    return (
        <MarketPage itemsList={props.itemsList} categoryId={4} />
    )
}


export default BustgalteryPage