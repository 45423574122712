import MarketPage from "../MarketPage";


const BustiePage = (props) => {
    return (
        <MarketPage itemsList={props.itemsList} categoryId={2} />
    )
}


export default BustiePage