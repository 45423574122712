import MarketPage from "../MarketPage";


const KupalnikiPage = (props) => {
    return (
            <MarketPage itemsList={props.itemsList} categoryId={7} />
    )
}


export default KupalnikiPage