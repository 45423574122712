import styled from 'styled-components';
import Header from "./components/Header";
import {
    BrowserRouter,
    Route,
    Link,
} from "react-router-dom";
import IndexPage from "./routes/IndexPage";
import CartPage from "./routes/CartPage";
import MarketPage from "./routes/MarketPage";
import Footer from "./components/Footer";
import ItemPage from "./routes/ItemPage";
import {useEffect, useState} from "react";
import FavPage from "./routes/FavPage";
import DostavkaPage from "./routes/DostavkaPage";
import BustiePage from "./routes/BustiePage";
import TrusikiPage from "./routes/TrusikiPage";
import PoyasaPage from "./routes/PoyasaPage";
import AccessoriesPage from "./routes/AccessoriesPage";
import BustgalteryPage from "./routes/BustgalteryPage";
import HomeMarketPage from "./routes/HomeMarketPage";
import OrderPage from "./routes/OrderPage";
import CertificatesPage from "./routes/CertificatesPage";
import {itemsList, mainBannerItems, topSellerItems} from "./utils/mock_data";
import KupalnikiPage from "./routes/KupalnikiPage";
import {Routes} from "react-router";
import PreloadPage from "./routes/PreloadPage/IndexPage";


const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function App() {

    const [cartItems, setCartItems] = useState([]);
    const [favAmount, setFavAmount] = useState(localStorage.getItem(`favs`));

    const [wishList, setWishList] = useState([]);
    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        //WISH_LIST
        const localWishList = localStorage.getItem("wishlist");
        localWishList && localWishList.length > 0 && setWishList(JSON.parse(localWishList));

        //CART_LIST
        const localCartList = localStorage.getItem("cartList");
        localCartList && localCartList.length > 0 && setCartItems(JSON.parse(localCartList));

        //CERTIFICATE
        const localCert = localStorage.getItem('certificates');
        localCert && localCert.length > 0 && setCertificates(JSON.parse(localCert));
    }, []);

    const isPreloader = false

    return (
        <BrowserRouter>
            <GlobalWrapper>
                {!isPreloader && <Header cartItems={cartItems} favAmount={favAmount}/>}
                <Routes>
                    {isPreloader && <Route path="/" element={<PreloadPage />}/>}
                    {!isPreloader &&
                    <>
                        <Route path="/" element={<IndexPage
                            itemList={itemsList}
                            mainBannerItems={mainBannerItems}
                            topSellerItems={topSellerItems}
                            itemsList={itemsList}/>}/>
                        <Route path="/dostavka" element={<DostavkaPage/>}/>
                        <Route path="/order/:orderId" element={<OrderPage itemList={itemsList}/>}/>
                        <Route path="/cart" element={<CartPage
                            certificates={certificates}
                            setCertificates={setCertificates}
                            cartItems={cartItems}
                            setCartItems={setCartItems}
                            itemList={itemsList}/>}/>
                        <Route path="/wishlist" element={<FavPage favAmount={favAmount} wishList={wishList} itemList={itemsList}/>}/>
                        <Route path="/item" element={<ItemPage
                            itemsList={itemsList}
                            setCartItems={setCartItems}
                            cartItems={cartItems}
                            favAmount={favAmount}
                            setFavAmount={setFavAmount}
                            wishList={wishList}
                            setWishList={setWishList}/>}/>
                        <Route path="/body" element={<MarketPage itemsList={itemsList} categoryId={0}/>}/>
                        <Route path="/home" element={<HomeMarketPage itemsList={itemsList}/>}/>
                        <Route path="/bustie" element={<BustiePage itemsList={itemsList}/>}/>
                        <Route path="/trusiki" element={<TrusikiPage itemsList={itemsList}/>}/>
                        <Route path="/byustgaltery" element={<BustgalteryPage itemsList={itemsList}/>}/>
                        <Route path="/poyasa" element={<PoyasaPage itemsList={itemsList}/>}/>
                        <Route path="/accessories" element={<AccessoriesPage
                            itemsList={itemsList}/>}/>
                        <Route path="/kupalniki" element={<KupalnikiPage
                            itemsList={itemsList}/>}/>
                        <Route path="/certificat" element={<CertificatesPage
                            certificates={certificates}
                            setCertificates={setCertificates}
                            cartItems={cartItems}
                            setCartItems={setCartItems}
                        />}/>
                    </>
                    }
                </Routes>
                {!isPreloader && <Footer/>}
            </GlobalWrapper>
        </BrowserRouter>
    );
}

export default App;
