import styled from 'styled-components';
import Logo from "../assets/logos/black_pilinia.png";
import Instagram from "../assets/logos/instagram.svg";
import Vk from "../assets/logos/vk.png";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 15px 0;
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  
  svg {
    width: 28px;
    height: 28px;
    background: none;
  }
  
  @media(max-width: 768px) {
    height: auto;
  }
`;

const DisplayLimitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    height: 100%;
    padding-top: 40px;
    justify-content: start;
    flex-direction: column;
  }
`;
const CartLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  transition: 0.2s;
  text-decoration: none;
  user-select: none;
  text-align: center;


  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    margin: 22px;
  }
`;


const ItemLink = styled.a`
  margin: 12px;
  cursor: pointer;

  img {
    width: 28px;
    height: 28px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
`;

const ImgPilinia = styled.img`
  height: 35px;
  margin-left: 22px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const Footer = () => {

    return (
        <HeaderWrapper>
            <MainWrapper>
                <DisplayLimitWrapper>
                    <Link to="/">
                        <ImgPilinia src={Logo} alt=""/>
                    </Link>

                    <CartLink to="/dostavka">
                        Условия доставки и возврата товара
                    </CartLink>

                    <ItemsWrapper>
                        <ItemLink href="https://www.instagram.com/piliniabyloskutova" target="_blank" rel="noreferrer">
                            <SVG src={Instagram} alt="inst"/>
                        </ItemLink>
                        <ItemLink href="https://www.vk.com/piliniabyloskutova" target="_blank" rel="noreferrer">
                            <img src={Vk} alt="vk"/>
                        </ItemLink>
                    </ItemsWrapper>
                </DisplayLimitWrapper>
            </MainWrapper>
            <MainWrapper style={{height: 50}}>
                <p style={{fontSize: 12, opacity: 0.75, textAlign: "center"}}> ИП Лоскутова Полина Романовна, ОГРНИП: 321547600064581, ИНН:
                    540225450784</p>
            </MainWrapper>
        </HeaderWrapper>

    );
};

export default Footer;