import MarketPage from "../MarketPage";


const TrusikiPage = (props) => {
    return (
        <MarketPage itemsList={props.itemsList} categoryId={3} />
    )
}


export default TrusikiPage