import styled from "styled-components";
import {useEffect, useState} from "react";
import {CATEGORY_LIST} from "../../utils/mock_data";
import Favorite from "../../assets/images/favorite.png";
import FavColored from "../../assets/images/favorite_color.png";
import AddItemInCartComp from "../../components/AddItemInCartComp";
import RecommendationsComponent from "../../components/RecommendationsComponent";

const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`;

const TopLinksWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 768px) {
    max-width: calc(100% - 40px);
  }
`;

const TopLink = styled.a`
  text-decoration: none;
  color: black;
  transition: 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;
const TopLinkDescription = styled.p`
  color: black;
  font-weight: 300;
`;
const LinkSpacer = styled.div`
  width: 1px;
  height: 14px;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  margin-top: 2px;
`;
const ShoppingWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: calc(100% - 30px);
  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ImageList = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  @media (max-width: 768px) {
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const ImageMain = styled.img`
  width: calc(100% - 20px);
  margin-left: 20px;
  height: 100%;
  object-fit: contain;
  
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    margin-left: 12px;
  }
`;

const ImageSwapButtonWrapper = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border: ${props => props.isClicked ? "1px solid #000" : "none"};
  cursor: pointer;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 50px;
  }
`;

const ImageSwapButton = styled.img`
  width: 100%;
`;

const BuySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
  padding-left: 70px;

  @media (max-width: 768px) {
    width: calc(100% - 50px);
    padding-left: 20px;
  }
`;

const FavoriteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;
const ColorWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
`;
const ColorList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  max-width: 500px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Price = styled.p`
  font-size: 16px;
  width: 100%;
`;
const ItemName = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
`;
const ColorText = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 300;
`;

const ColorButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: ${props => props.isActive ? "1px solid #333" : "1px solid #ccc"};
  box-shadow: ${props => props.isActive ? "0 0 0 1px #333" : "none"};
  border-radius: 100%;
  margin-top: 14px;
  margin-right: 14px;
  cursor: pointer;
  user-select: none;
`;
const ColorCircle = styled.div`
  width: 22px;
  height: 22px;
  background: ${props => props.color};
  border-radius: 100%;
`;

const SizesButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: ${props => props.isBigWord ? "80px" : "50px"};
  border: ${props => props.isActive ? "1px solid #000" : "1px solid #ccc"};
  background: ${props => props.isActive ? "#000" : "none"};
  margin-top: 14px;
  margin-right: 14px;
  cursor: pointer;

  p {
    font-weight: 300;
    font-size: 15px;
    color: ${props => props.isActive ? "#fff" : "#000"};
  }
`;

const BuyButton = styled.a`
  background: #000;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    background: #343434;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;



const ItemPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const pageSearch = document.location.search;
    const searchParams = new URLSearchParams(pageSearch);
    const itemId = searchParams.get("item");
    const cartItems = props.cartItems
    const itemsList = props.itemsList;
    const item = itemsList.find(searchItem => searchItem.id.toString() === itemId);
    const categoryId = item.categoryId;
    const wishList = props.wishList;
    const setWishList = props.setWishList;
    const setCartItems = props.setCartItems

    const [activeImg, setActiveImg] = useState(0);
    const [activeColor, setActiveColor] = useState(0);
    const [activeSize, setActiveSize] = useState(0);
    const [favColor, setFavColor] = useState(localStorage.getItem(`fav${itemId}`));

    // add item to cart block
    const [addItemBlock, setAddItemBlock] = useState(false);
    const [lastBuyedItem, setLastBuyedItem] = useState({
        name: item.name,
        image: `${item.img[0]}`,
        color: item.colors[activeColor].name,
        size: CATEGORY_LIST[categoryId].sizes[activeSize],
        price: item.price
    })


    const addToWishlist = () => {
        const check_wishlist = wishList.find((item) => item.id === itemId);
        const curerntNewWishList = [...wishList, {id: itemId}];
        if (!check_wishlist) {
            setWishList(curerntNewWishList);
            localStorage.setItem("wishlist", JSON.stringify(curerntNewWishList));
        } else {
            const deletedWishList = wishList.filter((item) => item.id !== itemId);
            setWishList(deletedWishList);
            localStorage.setItem("wishlist", JSON.stringify(deletedWishList));
        }
    };

    const addToFav = () => {
        if (favColor === '1') {
            setFavColor(null);
            localStorage.removeItem(`fav${itemId}`);
            localStorage.setItem('favs', parseInt(props.favAmount) - 1);
            props.setFavAmount(parseInt(props.favAmount) - 1);
        } else {
            setFavColor('1');
            localStorage.setItem(`fav${itemId}`, '1');
            localStorage.setItem('favs', props.favAmount ? parseInt(props.favAmount) + 1 : 1);
            props.setFavAmount(props.favAmount ? parseInt(props.favAmount) + 1 : 1);
        }
    };

    const addToCart = () => {
        const isExist = cartItems.length > 0;
        const index = isExist && cartItems.findIndex(x => x.id === itemId
            && x.color === item.colors[activeColor]
            && x.size === CATEGORY_LIST[categoryId].sizes[activeSize]);
        if (!isExist || index < 0) {
            const content = {
                name: item.name,
                id: itemId,
                color: item.colors[activeColor],
                size: CATEGORY_LIST[categoryId].sizes[activeSize],
                amount: 1
            }
            setCartItems([...cartItems, content]);
            localStorage.setItem("cartList", JSON.stringify([...cartItems, content]));
        } else {
            const arr = [...cartItems];
            const amnt = arr[index].amount;
            arr[index] = {
                name: itemsList[itemId].name,
                id: itemId,
                color: item.colors[activeColor],
                size: CATEGORY_LIST[categoryId].sizes[activeSize],
                amount: amnt + 1,
            };
            setCartItems(arr);
            localStorage.setItem("cartList", JSON.stringify(arr));
        }
        setLastBuyedItem({
            name: item.name,
            image: `${item.img[0]}`,
            color: item.colors[activeColor].name,
            size: CATEGORY_LIST[categoryId].sizes[activeSize],
            price: item.price
        })
        setAddItemBlock(true)
        setTimeout(() => setAddItemBlock(false),5000)
    };

    function stopTimerAndCloseWindow() {
        setAddItemBlock(false)
        const highestId = window.setTimeout(() => {
            for (let i = highestId; i >= 0; i--) {
                window.clearInterval(i);
            }
        }, 0);
    }

    return (

        <GlobalWrapper>
            {addItemBlock && <AddItemInCartComp
                name={lastBuyedItem.name}
                addItemBlock={addItemBlock}
                closeWindow={stopTimerAndCloseWindow}
                image={lastBuyedItem.image}
                color={lastBuyedItem.color}
                size={lastBuyedItem.size}
                price={lastBuyedItem.price}/>
            }
            <TopLinksWrapper>
                <TopLink href={CATEGORY_LIST[categoryId].link}>{CATEGORY_LIST[categoryId].name}</TopLink>
                <LinkSpacer/>
                <TopLinkDescription>{item.name}</TopLinkDescription>
            </TopLinksWrapper>

            <ShoppingWrapper>
                <ImagesWrapper>
                    <ImageList>
                        {item.img.map((x, id) => (
                            <ImageSwapButtonWrapper onClick={() => setActiveImg(id)} isClicked={activeImg === id}>
                                <ImageSwapButton src={`${x}`} alt=""/>
                            </ImageSwapButtonWrapper>
                        ))}
                    </ImageList>
                    <ImageMain src={`${item.img[activeImg]}`} alt=""/>
                </ImagesWrapper>

                <BuySection>
                    <FavoriteWrapper>
                        <ItemName> {item.name}</ItemName>
                        <a onClick={
                            () => {
                                addToFav();
                                addToWishlist();
                            }
                        }>
                            {!favColor && <img src={Favorite} alt="favorite" width={24}/>}
                            {favColor === '1' && <img src={FavColored} alt="favorite colored" width={24}/>}
                        </a>
                    </FavoriteWrapper>
                    <Price>{item.price} ₽</Price>
                    <ColorWrapper>
                        <ColorText> Цвет: {item.colors[activeColor].name}</ColorText>
                        <ColorList>
                            {item.colors.map((x, id) => (
                                <ColorButton isActive={activeColor === id} onClick={() => {setActiveColor(id);}}>
                                    <ColorCircle color={x.color}/>
                                </ColorButton>
                            ))}
                        </ColorList>
                    </ColorWrapper>

                    <ColorWrapper>
                        <ColorText>Размер: </ColorText>
                        <ColorList>
                            {CATEGORY_LIST[categoryId].sizes.map((x, id) => (
                                <SizesButton isActive={activeSize === id}
                                             isBigWord={x.length > 5}
                                             onClick={() => setActiveSize(id)}>
                                    <p>{x}</p>
                                </SizesButton>
                            ))}
                        </ColorList>
                    </ColorWrapper>
                    <BuyButton onClick={addToCart}>
                        <p>ДОБАВИТЬ В КОРЗИНУ</p>
                    </BuyButton>
                </BuySection>
            </ShoppingWrapper>
             <RecommendationsComponent
                 item={item}
                 itemList={itemsList}
                 setCartItems={setCartItems}
                 cartItems={cartItems}/>
        </GlobalWrapper>
    );
};


export default ItemPage;
