import styled from "styled-components";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {useParams} from "react-router";


const CartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  flex-direction: column;
  min-height: calc(100vh - 400px);
  width: 100%;
`;

const MainCartWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 200px);
  height: 100%;

  @media (max-width: 768px) {
    width: calc(100% - 22px);
    flex-direction: column;
  }
`;

const CardItemImg = styled.img`
  width: 140px;
  height: 200px;
  object-fit: cover;
`;
const ItemWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  padding: 20px 0;
  cursor: pointer;
  text-decoration: none;

  p {
    padding: 0;
    margin: 6px;
    color: #000;
  }

  &:hover {
    background: #f5f1f1;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: calc(100% - 10px);
    align-items: center;
  }
`;
const ContinueButton = styled.a`
  background: #000000;
  font-size: 15px;
  color: #fff;
  transition: 0.2s;
  text-decoration: none;
  cursor: pointer;
  width: 282px;
  text-align: center;
  padding: 16px 0;
  margin-top: 40px;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;


const OrderPage = (props) => {

    const params = useParams()
    const orderId = params["orderId"]
    const [payLink, setPayLink] = useState('')
    const [payStatus, setPayStatus] = useState("pending")
    const [cartItems, setCartItems] = useState([])
    const [price, setPrice] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const interval = 15000; // 15 seconds
        fetchData()

        async function fetchData() {
            axios.get(`https://functions.yandexcloud.net/d4elokdjskmo3gc0h7rk?orderId=${orderId}`, {
                headers: {"Access-Control-Allow-Origin": "*"}
            })
                .then(function (response) {
                    console.log(response);
                    setPayLink(response.data.Redirect)
                    setPayStatus(response.data.Status)
                    setCartItems(response.data.Products)
                    setPrice(response.data.TotalPrice)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        setInterval(() => fetchData(), interval);
    }, []);

    const itemList = props.itemList;
    const isPaid = payStatus === "succeeded"
    const findItemInListByIndex = (id) => itemList.find(searchItem => searchItem.id === id)

    return (
        <CartWrapper>
            <p style={{fontSize: 22, fontWeight: 300, letterSpacing: 3, marginTop: 40, textAlign: "center"}}>ВАШ ЗАКАЗ №105 {!isPaid && "НЕ"} ОПЛАЧЕН</p>
            <p style={{margin: 0}}>Общая стоимость заказа: {price.toFixed(0)} ₽</p>
            {!isPaid && <ContinueButton href={payLink}>Перейти к оплате</ContinueButton>}

            <p style={{fontSize: 22, fontWeight: 300, letterSpacing: 3, marginTop: 120}}>ПОДРОБНОСТИ ЗАКАЗА</p>
            <MainCartWrapper>
                {cartItems && cartItems.map((item, index) =>
                    <ItemWrapper to={item.Id >= 0 ? `/item/?item=${item.Id}` : '/certificat'}>
                        <CardItemImg src={item.Id >= 0 ? `${findItemInListByIndex(item.Id).img[0]}` : '/images/cert/cert-1.jpeg'}
                                     alt=""/>
                        {item.Id >= 0 && <p>Размер: {item.Size}</p>}
                        <p>{item.Id >= 0 ? findItemInListByIndex(item.Id).price : item.Amount} ₽</p>
                    </ItemWrapper>
                )}
            </MainCartWrapper>
        </CartWrapper>
    );
};


export default OrderPage;
